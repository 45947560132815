<template>
  <div>
    <filters
      :add="true"
      :search="true"
      @filtered-items="filter"
      @add-button-clicked="addBranch"
    />
    <b-card>
      <b-table
        hover
        :fields="columns"
        :items="GET_APPROVED_BRANCH"
        style-class="slots_text_align vgt-table bordered"
        show-empty
        @row-clicked="(item) => $router.push(`/branches-list/${item.id}`)"
      >

        <template
          #empty
        >
          <div class="text-center text-secondary">
            Нет данных для просмотра
          </div>
        </template>
        <template
          #cell(actions)="data"
        >
          <div class="d-flex align-items-center no-wrap">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon mr-50 p-25"
              size="sm"
              @click="$router.push(`/branches-list/${data.item.id}`)"
            >
              <feather-icon
                size="22"
                icon="ChevronsRightIcon"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon mr-50 p-25"
              size="sm"
              @click="editBranch(data.item)"
            >
              <feather-icon
                icon="EditIcon"
                size="22"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon p-25"
              size="sm"
              @click="modalDeleteBranch(data.item.id)"
            >
              <feather-icon
                size="22"
                icon="Trash2Icon"
              />
            </b-button>
          </div>
        </template>

        <template
          #cell(full_name)="data"
        >
          <span>
            {{ data.item.surename + ' ' + data.item.name + ' ' + data.item.middlename }}
          </span>
        </template>

        <template
          #cell(role)="data"
        >
          <span>
            {{ data.value.name }}
          </span>
        </template>

        <template
          #cell(last_login)="data"
        >
          <span>
            {{ dateTimeFormatter(data.value) }}
          </span>
        </template>

        <template #cell(is_active)="data">
          <b-form-checkbox
            :checked="data.value"
            name="check-button"
            switch
            inline
            disabled
          />
        </template>
      </b-table>

      <!-- pagination -->
      <!-- slot-scope="props" -->
      <template>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">{{ $t('general.show_from') }}</span>
            <b-form-select
              v-model="params.page_size"
              :options="['1', '5', '10', '15']"
              class="mx-1"
              @change="changePerPage"
            />
            <span class="text-nowrap"> {{ $t('general.show_to') }} </span>
            <!-- из {{ props.total }} -->
          </div>
          <div>
            <b-pagination
              v-model="params.page"
              :total-rows="rows"
              :per-page="params.page_size"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </b-card>

    <AddModal
      :branch-data="editBranchData"
      :is-edit="isEdit"
    />
  </div>
</template>

<script>
import {
  BCard,
  BPagination,
  BFormSelect,
  BButton,
  BFormCheckbox,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import AddModal from './components/AddModal.vue'
import toast from '@/mixins/toast'
import areYouSure from '@/mixins/areYouSure'
import filters from '@/components/filters.vue'

export default {
  name: 'User',
  components: {
    BTable,
    BCard,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BButton,
    AddModal,
    filters,
  },
  directives: {
    Ripple,
  },
  mixins: [toast, areYouSure],
  data() {
    return {
      isAddModal: false,
      isDeleteFileModal: false,
      rows: 1,
      searchTerm: '',
      editBranchData: null,
      isEdit: false,
      deleteItemId: null,
      params: {
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
      },
      columns: [
        {
          label: 'Название филиала',
          key: 'title',
        },
        {
          label: 'Лимит пользователей (Ед.)',
          key: 'capacity',
        },
        {
          label: 'Лимит памяти (GB)',
          key: 'storage',
        },
        {
          label: 'Дата создания',
          key: 'access_start_date',
        },
        {
          label: 'Дата окончения',
          key: 'access_end_date',
        },
        {
          label: 'Статус',
          key: 'is_active',
        },
        {
          label: 'Действия',
          key: 'actions',
        },
      ],
      items: [
        {
          id: 1,
          title: 'sergeli fillial',
          users_limit: '1500',
          memory_limit: '100',
          start_date: '2022-10-10',
          end_date: '2022-12-10',
          is_active: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('branches', ['GET_APPROVED_BRANCH', 'GET_REJECTED_BRANCH', 'GET_MODIFIED_BRANCH']),
  },
  mounted() {
    this.FETCH_APPROVED_BRANCH_LIST()
  },
  methods: {
    ...mapActions('branches', ['FETCH_APPROVED_BRANCH_LIST', 'FETCH_REJECT_BRANCH_LIST', 'FETCH_MODIFIED_BRANCH_LIST', 'DELETE_BRANCH', 'UPDATE_BRANCH']),
    modalDeleteBranch(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.UPDATE_BRANCH({ id, status: 'deleted' })
            .then(() => {
              this.$_successToast('Успешно удалено')
              this.FETCH_APPROVED_BRANCH_LIST(this.params)
            })
            .catch(() => {
              this.$_errorToast('Ошибка')
            })
        }
      })
    },
    addBranch() {
      this.isEdit = false
      this.$nextTick(() => {
        this.$bvModal.show('AddModalBranch')
      })
    },
    editBranch(data) {
      this.isEdit = true
      this.editBranchData = data
      this.$nextTick(() => {
        this.$bvModal.show('AddModalBranch')
      })
    },
    changePerPage(page_size) {
      this.params.page_size = page_size
      this.params.page = 1
      this.FETCH_APPROVED_BRANCH_LIST(this.params)
    },
    changePage(value) {
      this.params.page = value
      this.FETCH_APPROVED_BRANCH_LIST(this.params)
    },

    filter(val) {
      this.params.search = val.search
      this.FETCH_APPROVED_BRANCH_LIST(val)
    },
  },
}
</script>
