<template>
  <div>
    <b-row class="d-flex justify-content-between align-items-center mb-1 mb-lg-0">
      <b-col lg="10">
        <!-- Поиск: -->
        <b-row>
          <b-col
            v-if="search"
            sm="6"
            md="4"
            lg="3"
          >
            <b-form-group
              :label="$t('general.search')"
              label-for="search"
            >
              <b-input-group
                :label="$t('general.search')"
                label-for="search"
              >
                <b-input-group-prepend
                  is-text
                  class="border-right-0"
                >
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="search"
                  v-model="filtered.search"
                  :placeholder="$t('general.search') + '...'"
                  class="border-left-0 pl-0"
                  @input="emitFiltered"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="level"
            sm="6"
            md="4"
            lg="3"
          >
            <b-form-group
              :label="$t('users.level')"
              label-for="level"
            >
              <v-select
                id="level"
                v-model="filtered.level"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('users.level')"
                label="level"
                :options="GET_LEVELS_LIST"
                :reduce="el => el.id"
                @input="emitFiltered"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="gender"
            sm="6"
            md="4"
            lg="3"
          >
            <b-form-group
              :label="$t('users.gender')"
              label-for="gender"
            >
              <v-select
                id="gender"
                v-model="filtered.gender"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('users.gender')"
                label="title"
                :get-option-label="(e) => $t(e.title)"
                :options="genderOptions"
                :reduce="el => el.key"
                @input="emitFiltered"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="role"
            sm="6"
            md="4"
            lg="3"
          >
            <b-form-group
              :label="$t('users.role')"
              label-for="role"
            >
              <v-select
                id="role"
                v-model="filtered.role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('users.role')"
                label="title"
                :get-option-label="getLabel"
                :options="GET_USER_ROLES_LIST.results"
                :reduce="el => el.id"
                @input="emitFiltered"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="subject"
            sm="6"
            md="4"
            lg="3"
          >
            <b-form-group
              label="Предмет"
              label-for="subject"
            >
              <v-select
                id="subject"
                v-model="filtered.subjects"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Предмет"
                multiple
                :get-option-label="getLabel"
                :options="GET_SUBJECTS_LIST.results"
                :reduce="el => el.id"
                @input="emitFiltered"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="group"
            sm="6"
            md="4"
            lg="3"
          >
            <b-form-group
              :label="$t('users.class')"
              label-for="class"
            >
              <v-select
                id="class"
                v-model="filtered.group"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('users.class')"
                label="title"
                :options="GET_CLASS_LIST.results"
                :reduce="el => el.id"
                @input="emitFiltered"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <!-- Добавить -->
      <b-col
        v-if="add"
        class="text-right mt-25"
        lg="2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="addButtonEmit"
        >
          <feather-icon
            icon="PlusIcon"
          />
          {{ $t('general.add') }}
          <!--Добавить button-->
        </b-button>
      </b-col>
      <slot />
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BInputGroupPrepend, BInputGroup,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'Filters',
  components: {
    vSelect,
    // Datepicker,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: true,
    },
    level: {
      type: Boolean,
      default: false,
    },
    gender: {
      type: Boolean,
      default: false,
    },
    role: {
      type: Boolean,
      default: false,
    },
    subject: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Boolean,
      default: false,
    },
    groupOptions: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      filtered: {
        search: null,
        level: null,
        role: null,
        gender: null,
        subject: null,
        group: null,
      },
      genderOptions: [
        {
          key: 'male',
          title: 'users.male',
        },
        {
          key: 'female',
          title: 'users.female',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('users', ['GET_LEVELS_LIST', 'GET_USER_ROLES_LIST']),
    ...mapGetters('classes', ['GET_CLASS_LIST']),
    ...mapGetters('knowledgeBase', ['GET_SUBJECTS_LIST']),
  },
  mounted() {
    if (this.group) {
      this.FETCH_CLASS_LIST()
    }
    if (this.role) {
      this.FETCH_USER_ROLES_LIST()
    }
    if (this.subject) {
      this.FETCH_SUBJECTS_LIST()
    }
  },
  methods: {
    ...mapActions('users', [
      'FETCH_USER_ROLES_LIST',
    ]),
    ...mapActions('classes', ['FETCH_CLASS_LIST']),
    ...mapActions('knowledgeBase', ['FETCH_SUBJECTS_LIST']),
    getLabel(element) {
      if (element.title) return element.title[this.$i18n.locale]
      return element
    },
    emitFiltered() {
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {
        if (!this.filtered.search) {
          this.$set(this.filtered, 'search', null)
        }
        if (!this.filtered.level) {
          this.$set(this.filtered, 'level', null)
        }
        if (!this.filtered.role) {
          this.$set(this.filtered, 'role', null)
        }
        if (!this.filtered.group) {
          this.$set(this.filtered, 'group', null)
        }
        if (!this.filtered.gender) {
          this.$set(this.filtered, 'gender', null)
        }
        if (!this.filtered.subjects) {
          this.$set(this.filtered, 'subjects', null)
        }
        this.$emit('filtered-items', this.filtered)
      }, 600)
    },
    addButtonEmit() {
      this.$emit('add-button-clicked')
    },
  },
}
</script>

<style scoped>
</style>
